// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlApi: 'https://generardiarioscontabilidad.pseperu.pe',    // https://test-backend.codeplex.pe    http://localhost:8000     https://test-backend.pseperu.pe
  firebase: {
    apiKey: "AIzaSyDU8Ig6Vo4nTYnFWSUSVUdaBd8phTwMorw",
    authDomain: "apppedrorla.firebaseapp.com",
    databaseURL: "https://apppedrorla.firebaseio.com",
    projectId: "apppedrorla",
    storageBucket: "apppedrorla.appspot.com",
    messagingSenderId: "686551268718"
  },
  domin:'localhost'
};