import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
  } from '@angular/router';




@Injectable({
    providedIn: 'root'
  })
  export class ValidAccessResolver implements Resolve<any> {

    constructor(
        private router: Router
      ){
    
      }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const { token, idempresa, idsucursal, idusuario, idaniopro, idmes } = route.params;

        if(token){

            sessionStorage.setItem('token',token);
            sessionStorage.setItem('idempresa',idempresa);
            sessionStorage.setItem('idsucursal',idsucursal);
            sessionStorage.setItem('idusuario',idusuario);
            sessionStorage.setItem('idaniopro', idaniopro);
            sessionStorage.setItem('idmes', idmes  );
            
            this.router.navigate(['/libro-diarios']);
        }else {
          this.router.navigate(['/404']);
        }
    }


  }